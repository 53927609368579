<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Odontodocs 2.0
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Redefinir Senha 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          A nova senha deve conter, no mínimo 8 digitos, 1 letra maiúscula, 1 letra minúscula e um número.
        </b-card-text>

        <b-alert variant="danger" v-if="errors" show>
          <div class="alert-body" v-for="(error, index) in errors" :key="index">
            {{error[0]}}
          </div>
        </b-alert>

        <b-alert variant="danger" v-if="errorsToken" show>
          <div class="alert-body">
            {{ errorsToken }}
          </div>
        </b-alert>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
          >
            <b-form-group label="Email" label-for="login-email">
              <validation-provider #default="{ errors }"  name="Email" rules="required|email">
                <b-form-input id="login-email" v-model="email"  :state="errors.length > 0 ? false:null" name="login-email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
                label="Nova Senha"
                label-for="reset-password-new"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  vid="Password"
                  rules="required|password|min:8"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
                label-for="reset-password-confirm"
                label="Confirmar a Senha"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Confirmar Senha"
                  rules="required|confirmed:Password"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
                block
                type="submit"
                variant="primary"
                :disabled="proDisabled"
            >
              <b-spinner small v-if="spinner" />
              <span v-if="spinner">Aguarde...</span>
              <span v-else>Cadastrar Nova Senha</span>
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para Login
          </b-link>
        </p>

      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BAlert,
    BSpinner,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {

      cPassword: '',
      password: '',
      email: '',
      token: '',
      spinner: false,
      proDisabled: false,
      errors: [],
      errorsToken: false,
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  created(){

    this.token = this.$route?.query?.token || '';
    this.email = this.$route?.query?.email || '';

    if(!this.token){
      this.$router.push({ name: 'login'})
    }


  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.spinner = true
          this.proDisabled = true

          const payload = {
            'password': this.cPassword,
            'token': this.token,
            'email': this.email
          }

          this.$http.post('odontodocs/reset-password', payload).then( res => {

            this.spinner = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Senha Redefinida com Sucesso!!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })

            this.$router.push({ name: 'login' })


          })
              .catch( error => {
                this.spinner = false
                this.proDisabled = false

                if(error.response.status == 422)
                {
                  this.errors = error.response.data.errors
                }

                if(error.response.status == 400)
                {
                  this.errorsToken = error.response.data.message
                }

              })

        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
